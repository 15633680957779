import { useStaticQuery, graphql } from 'gatsby'

const rejectList = ['BrewDog']

export default () => {
  const { erudusStats } = useStaticQuery(graphql`
    query MyQuery {
      erudusStats {
        productCount
        manufacturerCount
        wholesalerCount
        catererRetailerCount
        manufacturers {
          name
        }
        wholesalers {
          name
        }
      }
    }
  `)

  erudusStats.manufacturers = erudusStats.manufacturers.filter(
    (m) => !rejectList.includes(m.name)
  )

  erudusStats.wholesalers = erudusStats.wholesalers.filter(
    (m) => !rejectList.includes(m.name)
  )

  return erudusStats
}
