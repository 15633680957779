import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import _chunk from 'lodash/chunk'

import useErudusStats from '../hooks/useErudusStats'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import Newsletter from '../components/Newsletter'

const AllUsersPage = ({ path }) => {
  const {
    markdownRemark,
    productImage,
    manufacturerImage,
    wholesalerImage,
  } = useStaticQuery(graphql`
    query AllUsersPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/whos-using-erudus" } }) {
        frontmatter {
          title
          path
        }
      }
      productImage: file(
        relativePath: { eq: "articles/data-pool/products.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      manufacturerImage: file(
        relativePath: { eq: "articles/data-pool/manufacturers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wholesalerImage: file(
        relativePath: { eq: "articles/data-pool/wholesalers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {
    productCount,
    manufacturerCount,
    wholesalerCount,
    wholesalers,
    manufacturers,
  } = useErudusStats()

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-allUsers">
      <SEO title={pageData.title} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <section className="c-section c-section--datapool">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-articleDatapool c-articleFull">
              <h1 className="c-articleFull__title">{pageData.title}</h1>
              <div className="c-articleDatapool__header">
                <div className="row">
                  <div className="c-articleDatapoolStat col-sm-4">
                    <Img
                      fluid={productImage.childImageSharp.fluid}
                      alt="Product specifications"
                      className="c-articleDatapoolStat__image img-fluid"
                    />
                    <p className="c-articleDatapoolStat__text">
                      <strong>{productCount}</strong>
                      Product Specifications
                    </p>
                  </div>
                  <div className="c-articleDatapoolStat col-sm-4">
                    <Img
                      fluid={manufacturerImage.childImageSharp.fluid}
                      alt="Manufacturers"
                      className="c-articleDatapoolStat__image img-fluid"
                    />
                    <p className="c-articleDatapoolStat__text">
                      <strong>{manufacturerCount}</strong>
                      Food Manufacturers
                    </p>
                  </div>
                  <div className="c-articleDatapoolStat col-sm-4">
                    <Img
                      fluid={wholesalerImage.childImageSharp.fluid}
                      alt="Wholesalers"
                      className="c-articleDatapoolStat__image img-fluid"
                    />
                    <p className="c-articleDatapoolStat__text">
                      <strong>{wholesalerCount}</strong> Wholesalers
                    </p>
                  </div>
                </div>
              </div>
              <div className="c-articleFull__section">
                <h2 className="c-articleDatapool__sectionHeader">
                  Wholesalers
                </h2>
                <div className="row">
                  {_chunk(wholesalers, Math.ceil(wholesalers.length / 3)).map(
                    (_wholesalers, i) => (
                      <div
                        key={`_wholesalers_${i}`}
                        className="col-md-6 col-lg-4"
                      >
                        <div className="c-articleDatapool__sectionBody">
                          <ul className="pl-4">
                            {_wholesalers.map((wholesaler, i) => (
                              <li key={wholesaler.name} className="p-0">
                                {wholesaler.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="c-articleFull__section">
                <h2 className="c-articleDatapool__sectionHeader">
                  Manufacturers
                </h2>
                <div className="row">
                  {_chunk(
                    manufacturers,
                    Math.ceil(manufacturers.length / 3)
                  ).map((_manufacturers, i) => (
                    <div
                      key={`_manufacturers_${i}`}
                      className="col-md-6 col-lg-4"
                    >
                      <div className="c-articleDatapool__sectionBody">
                        <ul className="pl-4">
                          {_manufacturers.map((manufacturer, i) => (
                            <li key={manufacturer.name}>{manufacturer.name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default AllUsersPage
